import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './views/Login';
import Home from './views/Home';
import User from './views/User';
import Type from './views/type/Type';
import Machine from './views/Machine';
import Scan from './views/Scan';
import Order from './views/Order';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/page' element={<Home />} >
          <Route path='user' element={<User />} />
          <Route path='type' element={<Type />} />
          <Route path='machine' element={<Machine />} />
          <Route path='order' element={<Order />} />
        </Route>
        <Route path='/scan' element={<Scan />} />
        
      </Routes>
    </Router>
  );
}

export default App;
