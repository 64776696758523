import React, {useEffect, useState} from 'react';
import backImg1 from '../static/img/back1.jpg';
import temTit from '../static/img/tem-tit.png';
import luBack from '../static/img/lu-back.png';
import luClose from '../static/img/lu-close.png';
import luTit from '../static/img/lu-tit.png';
import scanBorde from '../static/img/scan-border.png';
import luDesc from '../static/img/lu-desc.png';

document.documentElement.style.fontSize = document.documentElement.clientWidth / 750 + 'px';

export default function Scan() {
    const [showMovie, setShowMovie] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowMovie(true);
        }, 500)
        
    }, [])
    
    return (
        <div style={{ width: '100%', height: '100%', position: 'relative', background: 'rgb(179, 31, 31)', display: 'flex', flexDirection: 'column', }}>
            
            <div style={{ position: 'relative', display: 'flex', paddingTop: '23.5rem' }}>
                <img src={backImg1} style={{ width: '100%', position: 'relative', }} />
                
                <img src={temTit} style={{ width: '81.466%', position: 'absolute', zIndex: 1, left: 0, right: 0, margin: 'auto', top: '78em', }} />
            </div>

            
            <div style={{ position: 'relative', marginTop: '-32.88%', flex: 1, }}>
                <img src={luBack} style={{ width: '100%', position: 'absolute'}}  />
                
                <div style={{ position: 'relative', zIndex: 1, height: '100%', }} >
                    {!showMovie &&
                        <div style={{ margin: '76em 48rem 60rem', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: `url(${luClose})`, backgroundSize: '100% 100%', }} >
                            <img src={luTit} style={{ width: '332em', position: 'relative', margin: '138em auto 0', }} />
                            <div>
                                <img src={scanBorde} style={{ width: '537em', position: 'relative', margin: '40em auto 0', }} />
                                
                            </div>
                            <img src={luDesc} style={{ width: '356em', position: 'relative', margin: '40em auto 20em', }} />
                        </div>
                    }
                    
                </div>
                
            </div>
            
        </div>
    )
}
