import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table, Pagination, Modal, message, Space } from 'antd';
import _axios from '../unit/axios';

function Type() {
    
    const [isinit, setIsinit] = useState(false);
    
    useEffect(() => {
        if (!isinit) {
            getList();
            setIsinit(false);
        }
        
    }, [])
    
    const [loading, setLoading] = useState(false);
    const [seachource, setSeachSource ] = useState({});
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [total, setTotal ] = useState(0);

    const getList = async () => {
        var p = {
            page,
            size,
            ...seachource,
        }
        setLoading(true);
        _axios.get('/api.v1/machine/list', { params: p }).then(res => {
            console.log(res.data)
            setList(res.data.list || []);
            setTotal(res.data.total || 0);
            setLoading(false);
        }).catch(err => {
            console.log(err)
            setLoading(false);
        })
    }
    
    const onFinish = (values) => {
        console.log('Success:', values);
        for (const key in values) {
            if (values[key] === null || values[key] === undefined) {
                delete values[key];
            }
        }
        setSeachSource(values);
        getList();
    };
      
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const columns = [{
        title: '编号',
        dataIndex: 'id',
        key: 'id',
    },{
        title: '名称',
        dataIndex: 'name',
        key: 'name',
    }, {
        title: '操作',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <a onClick={() => showModal('edit', record)}>修改</a>
                <a onClick={() => deleteRow(record)}>删除</a>
            </Space>
        ),
    }];
    
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState({});
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [delModalShow, setDelModalShow] = useState(false);

    const showModal = (type = 'add', row) => {
        var data = { name: '', type };
        if (row) {
            data = Object.assign(data, row);
        }
        form.setFieldsValue(data);
        setModalData(data);
        setModalShow(true);
    }

    const saveType = () => {
        form.submit();
    }
    
    const submit = (values) => {
        setLoading(true);
        _axios.put('/api.v1/machine/store', Object.assign(modalData, values)).then(res => {
            messageApi.open({
                type: 'success',
                content: '提交成功',
            })
            setModalShow(false);
            setLoading(false);
            getList();
        }).catch(err => {
            messageApi.open({
                type: 'error',
                content: '提交失败',
            })
            setLoading(false);
        })
    }
    
    const deleteRow = (row) => {
        setDelModalShow(row.id);
    }

    const delType = () => {
        setLoading(true);
        _axios.delete('/api.v1/machine/del', {data: {id: delModalShow}}).then(res => {
            messageApi.open({
                type: 'success',
                content: '删除成功',
            })
            setDelModalShow(false);
            setLoading(false);
            getList();
        }).catch(err => {
            messageApi.open({
                type: 'error',
                content: '删除失败',
            })
            setLoading(false);
        })
    }

    return (
        <div style={{padding: '20px'}}>
            <div style={{marginBottom: '20px'}}>
                <Button type="primary" onClick={() => showModal()}>
                    新增
                </Button>
            </div>
            
            <Table dataSource={list} columns={columns} pagination={false} rowKey={'id'}></Table>

            <div style={{marginTop: '20px'}}>
                <Pagination current={page} pageSize={size} total={total} align='end'
                    onChange={(page, size) => {
                        setPage(page);
                        setSize(size);
                        getList();
                    }}
                />
            </div>

            <Modal title={modalData.type == 'add' ? '新增' : '修改'} open={modalShow} onOk={saveType} onCancel={() => setModalShow(false)}  okText="提交" cancelText="取消">
                <Form form={form} name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} onFinish={submit} autoComplete="off" disabled={loading}>
                    <Form.Item label="名称" name="name" rules={[{ required: true, message: '名称不能为空' }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            
            <Modal title={'警告'} open={delModalShow} onOk={delType} onCancel={() => setDelModalShow(false)}  okText="确定" cancelText="取消">
                确定删除该烧金机设备吗？
            </Modal>
            

            {contextHolder}
        </div>

    )
}
export default Type;