import React, { useState } from 'react';
import { Input, Button } from "antd";
import styled from 'styled-components';
import _axios from '../unit/axios';
import backimg from '../static/img/login-back.jpg';
import { useNavigate } from 'react-router-dom';

function Index() {
    
    const [username, setUsername] = useState('admin');
    const usernameChange = (e) => {
        setUsername(e.target.value);
    }

    const [password, setPassword] = useState('admin889');
    const passwordChange = (e) => {
        setPassword(e.target.value);
    }

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const submit = async () => {
        setLoading(true);
        _axios.post('/api.v1/admin/login', { username, password }).then(res => {
            localStorage.setItem('user', JSON.stringify(res.data));
            navigate('/page/user');
        }).catch(err => {
            console.log(err)
            setLoading(false);
        })
    }
    
    const Inputpapa = styled(Input)`
        margin-top: 10px;
        margin-bottom: 12px;
        width: 280px;
    `
    
    return (
        <div style={{width: '100%',height: '100%', position: 'relative',display: 'flex', flexDirection: 'column' }}>
            <div style={{ position: 'relative', flex: 1, width: '100%',justifyContent: 'center', alignItems: 'center', display: 'flex', }}>
           
                <img src={backimg} style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }} alt='' />
                
                <div style={{ position: 'relative', padding: '20px', borderRadius: '10px', backgroundColor: 'rgba(255,255,255,0.65)', width: '280px' }}>
                    <div>
                        <div style={{fontSize: '16px'}}>账号</div>
                        <Inputpapa type="text" value={ username } onChange={usernameChange} placeholder='请输入账号'></Inputpapa>
                        <div style={{fontSize: '16px'}}>密码</div>
                        <Inputpapa type="password" value={password} onChange={passwordChange} placeholder='请输入密码'></Inputpapa>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                        <Button disabled={loading} onClick={submit} type="primary">提交</Button>
                    </div>
                </div>
            </div>

            <div style={{padding: '12px', textAlign: 'center', color: '#666', fontSize: '12px'}}>
                福建开三像技术服务有限公司&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<a href="https://www.miit.gov.cn/">闽ICP备2023015373号</a>
            </div>
            
        </div>    
    )
}

export default Index;