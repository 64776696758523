import axios from "axios";
import { getSign, getNonceStr, getEncrypt } from '../static/js/encryptSign.js';

// 创建axios实例
const _axios = axios.create({
    // baseURL: process.env.NODE_ENV==='development' ? '/apipapa' : '', // api的base_url
    baseURL: 'https://api.kaisx.com',
    timeout: 10000, // 请求超时时间
});

// 请求拦截器
_axios.interceptors.request.use(
    (config) => {
        var d = config.params || config.data || {}; 
        let data = {
            timestamp: new Date().getTime(), //时间戳
            nonceStr: getNonceStr(32), //随机字符串
            ...d
        }
        if (config.url == '/api.v1/admin/login' && data.password) {
            data.password = getEncrypt(data.password); //密码加密
        } else {
            config.headers['X-Auth-Token'] = JSON.parse(localStorage.getItem('user')).token;
        }
        if (config.url == '/api.v1/upload/file') {
            config.headers['Content-Type'] = 'multipart/form-data';
        }
        config.headers['X-Auth-sign'] = getSign(data);
        config.data = data;
        config.params = data;
        return config;
    },
    (error) => {
        // 请求错误处理
        console.log(error); // for debug
        Promise.reject(error);
    }
);

// 响应拦截器
_axios.interceptors.response.use(
    (response) => {
        // 对响应数据做处理，例如只需要data字段
        const res = response.data;
        // if (res.code !== 20000) {
        //   Message({
        //     message: res.message,
        //     type: 'error',
        //     duration: 5 * 1000
        //   });
        //   // 50001: 非法token; 50002: 其他客户端错误; 50003: 认证失败
        //   if (res.code === 50001 || res.code === 50002 || res.code === 50003) {
        //     // 重新登录
        //     MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确认登出', {
        //       confirmButtonText: '重新登录',
        //       cancelButtonText: '取消',
        //       type: 'warning'
        //     }).then(() => {
        //       store.dispatch('FedLogOut').then(() => {
        //         location.reload(); // 为了重新实例化vue-router对象 防止bug
        //       });
        //     });
        //   }
        //   return Promise.reject('error');
        // } else {
        //   return response.data;
        // }
        return res;
    },
    (error) => {
        console.log("err" + error); // for debug
        return Promise.reject(error);
    }
);

export default _axios;
