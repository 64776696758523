import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table, Pagination } from 'antd';
import _axios from '../unit/axios';

function User() {
    
    const [isinit, setIsinit] = useState(false);
    
    useEffect(() => {
        if (!isinit) {
            getList();
            setIsinit(false);
        }
        
    }, [])
    
    const [loading, setLoading] = useState(false);
    const [seachource, setSeachSource ] = useState({});
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [total, setTotal ] = useState(0);

    const getList = async () => {
        var p = {
            page,
            size,
            ...seachource,
        }
        setLoading(true);
        _axios.get('/api.v1/users/list', { params: p }).then(res => {
            console.log(res.data)
            setList(res.data.list || []);
            setTotal(res.data.total || 0);
            setLoading(false);
        }).catch(err => {
            console.log(err)
            setLoading(false);
        })
    }
    
    const onFinish = (values) => {
        console.log('Success:', values);
        for (const key in values) {
            if (values[key] === null || values[key] === undefined) {
                delete values[key];
            }
        }
        setSeachSource(values);
        getList();
    };
      
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const columns = [{
        title: '微信昵称',
        dataIndex: 'nickname',
        key: 'nickname',
    }, {
        title: '祈福名称',
        dataIndex: 'name',
        key: 'name',
    }, {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: function (text, record) {
            return record.status == 0 ? '正常' : '停用'
        }
    }, {
        title: '创建时间',
        dataIndex: 'create_at',
        key: 'create_at',
    }]

    return (
        <div style={{padding: '20px'}}>
            <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" layout="inline">
                <Form.Item label="祈福名称" name="name">
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={loading}>
                        查询
                    </Button>
                </Form.Item>
            </Form>
            
            <Table dataSource={list} columns={columns} pagination={false} >
                {/* <Column title="操作" key="action"
                    render={(_, record) => (
                        <Space size="middle">
                            <a>Invite {record.lastName}</a>
                            <a>Delete</a>
                        </Space>
                    )}
                /> */}
            </Table>

            <div style={{marginTop: '20px'}}>
                <Pagination current={page} pageSize={size} total={total} align='end'
                    onChange={(page, size) => {
                        setPage(page);
                        setSize(size);
                        getList();
                    }}
                />
            </div>
        </div>

    )
}
export default User;