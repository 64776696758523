import React, { useState, useEffect } from 'react'
import { Menu } from 'antd';
import { UserOutlined, TagsOutlined, FireOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function Left() {
    useEffect(() => {
        checkMenu();
    }, [])

    const checkMenu = () => {
        const path = window.location.pathname.split('/')[2];
        setActiveKey([path]);
    }

    const [activeKey, setActiveKey] = useState([]);
    
    const menus = [{
        key: 'user', icon: <UserOutlined />, label: '用户管理',
    }, {
        key: 'type', icon: <TagsOutlined />, label: '祈福类型',
    }, {
        key: 'machine', icon: <FireOutlined />, label: '烧金机',
    }, {
        key: 'order', icon: <MoneyCollectOutlined />, label: '订单管理',
    }]
    
    const navigate = useNavigate();
    const menuSelect = (item) => {
        navigate(`/page/${item.key}`);
        checkMenu();
    }
    
    return (
        <div style={{width: '280px',height: '100%', borderRight: '1px solid #1677FF', padding: '10px',background: 'rgb(0,21,41)',}}>
            <Menu selectedKeys={activeKey} mode="inline" theme="dark" items={menus} onSelect={menuSelect} />
            
        </div>
    )
}
