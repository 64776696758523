import React from 'react';
import Head from './component/Head';
import Left from './component/Left';
import { Outlet } from 'react-router-dom';

function Home() {
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%',}}>
            <Head></Head>
            <div style={{display: 'flex', flex: 1,}}>
                <Left></Left>
                <div style={{ flex: 1, height: '100%' }}>
                    <Outlet />
                </div>
            </div> 
            
        </div>
    )
}
export default Home;